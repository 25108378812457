<template>
  <div class="clienteleInfo">
    <div class="searchNav">
      <el-button
        class="button"
        icon="el-icon-plus"
        type="primary"
        style="margin-left: 0"
        @click="handleAdd"
        >新增</el-button
      >
      <el-button class="button" type="text" @click="handleDownload"
        >下载模板</el-button
      >
      <el-button class="button" type="text" @click="event">导入数据</el-button>
      <!-- <el-button class="button" type="text" @click="openGrouplist">创建订阅组</el-button>
      <el-button class="button" type="text" @click="openGroupView">查看订阅组</el-button> -->
      <div class="searchParams">
        <div>
          <radioGroup @radioChange="radioChange" @complete="radioComplete" />
        </div>
        <el-input
          class="input"
          v-model="queryParam.keyword"
          placeholder="请输入编号/名称"
          clearable
          @keydown.enter.native="getDataList"
        ></el-input>
        <el-button class="button" @click="getDataList">查询</el-button>
      </div>
    </div>
    <div>
      <el-table
        :data="tableData"
        v-loading="loading"
        style="width: 100%"
        :max-height="height"
      >
        <!-- <el-table-column prop="CompanyName" label="所属公司" width="200">
        </el-table-column> -->
        <el-table-column prop="cCusCode" label="客户编号" width="120">
        </el-table-column>
        <el-table-column prop="cCusName" label="客户名称" width="240">
        </el-table-column>
        <el-table-column
          prop="cCusAddress"
          label="地址"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column label="操作" width="70" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleView(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="total,prev, pager, next,sizes"
        :total="pagination.total"
        :current-page="pagination.current"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.pageSizeOptions"
        style="text-align: right; margin-top: 8px; padding: 0"
        @current-change="currentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="导入数据"
      :visible.sync="dialogVisible"
      width="20%"
      destroy-on-close
    >
      <el-upload
        style="text-align: center"
        class="upload-demo"
        :action="`${$baseUrl}/BO/CustomerInfo/Import?CompanyId=${this.queryParam.SupplieId}`"
        :on-success="handlesuccess"
        :on-progress="handleprogres"
        :limit="10"
        :on-exceed="handleExceed"
        :file-list="fileList"
      >
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
    </el-dialog>
    <EditForm ref="editForm" :parentObj="{ ...this }" />
    <Grouplist ref="Grouplist" />
    <GroupView ref="GroupView" />
  </div>
</template>

<script>
import radioGroup from "@/components/CompanyRadioGroup";
import EditForm from "./clienteleInfoForm.vue";
import { download } from "@/utils/Tools";
import Grouplist from "@/components/Grouplist"; //新增群组
import GroupView from "@/components/GroupView"; //群组记录
export default {
  props: {},
  components: {
    EditForm,
    radioGroup,
    Grouplist,
    GroupView,
  },
  mounted() {},
  created() {},
  data() {
    return {
      fileList: [],
      dialogVisible: false,
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "Id",
        order: "desc",
      },
      tableData: [],
      queryParam: { condition: "cCusName", SupplieId: "全部" },
      loading: false,
    };
  },
  computed: {
    height() {
      return document.documentElement.clientHeight * 0.75;
    },
  },
  methods: {
    handleprogres(event, file, fileList) {
      // console.log(event, file, fileList, '上传中')
    },
    handlesuccess(response, file, fileList) {
      if (response.Success) {
        this.$message.success(response.Msg);
        this.dialogVisible = false;
        setTimeout(() => {
          this.getDataList();
        });
      } else {
        this.$message.error(response.Msg);
        this.fileList = [];
      }
    },
    event() {
      if (this.queryParam.SupplieId.split(",").length > 1) {
        this.$message.error("请选择公司");
        return;
      }
      this.dialogVisible = true;
    },
    handleExceed() {
      this.$message.error("已超出文件列表最大数，请删除后上传");
      console.log("文件超出限制");
    },
    handleDownload() {
      download(
        this.$baseUrl + "/BO/CustomerInfo/ExportToExcel ",
        "客户导入模板"
      );
    },

    openGrouplist() {
      console.log("打开群组列表");

      if (this.queryParam.SupplieId.split(",").length > 1) {
        this.$message.error("请选择公司");
        return;
      }
      this.$refs.Grouplist.openForm(this.queryParam.SupplieId);
    },
    openGroupView() {
      console.log("查看群组记录");
      if (this.queryParam.SupplieId.split(",").length > 1) {
        this.$message.error("请选择公司");
        return;
      }
      this.$refs.GroupView.openForm(this.queryParam.SupplieId);
    },
    radioComplete(e) {
      if (e) {
        this.queryParam.SupplieId = e;
        this.getDataList();
      }
    },
    radioChange(e) {
      console.log(e);
      if (e == "more") return;
      this.queryParam.SupplieId = e;
      this.getDataList();
    },
    handleAdd() {
      if (this.queryParam.SupplieId.split(",").length > 1) {
        this.$refs.editForm.openForm();
      } else {
        this.$refs.editForm.openForm(null, this.queryParam.SupplieId);
      }
    },
    handleView(row) {
      console.log(row);
      this.$refs.editForm.openForm(row.Id, row.CompanyId);
    },
    currentChange(page) {
      this.pagination.current = page;
      this.getDataList();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      this.$http
        .post("/BO/CustomerInfo/GetDataList", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || "Id",
          SortType: this.sorter.order,
          Search: this.queryParam,
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success) {
            this.tableData = resJson.Data;
            if (this.tableData.length > 0) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          }
        });
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.el-button--text {
    border-color: transparent;
    color: #1aad19;
    background: 0 0;
    padding-left: 0;
    padding-right: 0;
}
/deep/ .el-menu-item.is-active {
    color: #1aad19;
}
.el-button--primary {
    color: #FFF;
    background-color: #1aad19;
    border-color: #1aad19;
}
/deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
    color: #FFF;
    background-color: #1aad19;
    border-color: #1aad19;
}
/deep/.el-radio-button__inner:hover {
    color: #1aad19;
}</style>
