<template>
  <el-drawer
    :title="title"
    :visible.sync="drawer"
    :direction="direction"
    :before-close="handleClose"
    destroy-on-close
    custom-class="drawerClass"
  >
    <el-form ref="form" :model="entity" label-width="100px" :rules="rules">
      <el-form-item label="所属公司">
        <CompanySearchSelect
          v-model="entity.CompanyId"
          @select="CompanySelect"
        />
      </el-form-item>
      <el-form-item label="客户编码" prop="cCusCode">
        <el-input
          v-model="entity.cCusCode"
          :disabled="this.title == '编辑'"
          placeholder="请输入客户编码"
        ></el-input>
      </el-form-item>
      <el-form-item label="客户名称" prop="cCusName">
        <el-input
          v-model="entity.cCusName"
          placeholder="请输入客户名称"
        ></el-input>
      </el-form-item>
      <!--  <el-form-item label="账期(天)" prop="DelayDays">
        <el-input
          v-model="entity.DelayDays"
          placeholder="请输入账期(天)"
        ></el-input>
      </el-form-item> -->
      <el-form-item label="客户联系人" prop="cCusPerson">
        <el-input
          v-model="entity.cCusPerson"
          placeholder="请输入客户联系人"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系方式" prop="cCusPhone">
        <el-input
          v-model="entity.cCusPhone"
          placeholder="请输入客户联系人电话"
        ></el-input>
      </el-form-item>
      <el-form-item label="开启验证" prop="cCusPhone">
        <el-radio-group v-model="entity.IscheckPhone">
          <el-radio-button :label="true">是</el-radio-button>
          <el-radio-button :label="false">否</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="省/直辖市">
        <el-select
          v-model="entity.cProvince"
          placeholder="请选择省/直辖市"
          @change="changeProvince"
        >
          <el-option
            v-for="item in ProvinceList"
            :key="item.Id"
            :value="item.Id"
            :label="item.Name"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="市">
        <el-select
          v-model="entity.cCity"
          placeholder="请选择市"
          @change="changeCity"
        >
          <el-option
            v-for="item in CityList"
            :key="item.Id"
            :value="item.Id"
            :label="item.Name"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="区">
        <el-select v-model="entity.cCounty" placeholder="请选择区">
          <el-option
            v-for="item in CountyList"
            :key="item.Id"
            :value="item.Id"
            :label="item.Name"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="地址" prop="cCusAddress">
        <el-input
          v-model="entity.cCusAddress"
          type="textarea"
          :autosize="{ minRows: 4 }"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="启用授权码" prop="cCusAddress">
        <el-switch v-model="entity.ISsignCode"></el-switch>
      </el-form-item> -->
      <el-form-item v-if="entity.ISsignCode" label="授权码">
        <el-input
          v-model.number.trim="entity.SmsCode"
          maxlength="6"
          placeholder="静态授权码"
        ></el-input>
      </el-form-item>
      <el-form-item label="签章">
        <el-upload
          class="avatar-uploader"
          action="https://jsonplaceholder.typicode.com/posts/"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <img
            class="avatar"
            v-if="entity.CustomerSign"
            :src="entity.CustomerSign"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
    </el-form>
    <div style="height: 50px"></div>
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '5px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 99999,
      }"
    >
      <el-button @click="drawer = false">取消</el-button>
      <el-button type="primary" :loading="btnLoading" @click="handleSubmit"
        >保存</el-button
      >
    </div>
  </el-drawer>
</template>

<script>
import CompanySearchSelect from "@/components/CompanySearchSelect.vue";
export default {
  components: {
    CompanySearchSelect,
  },
  props: {
    parentObj: { type: Object },
  },
  data() {
    return {
      drawer: false,
      direction: "rtl",
      // sign: "",
      entity: {},
      CustomerSign: "",
      rules: {
        cCusName: [
          { required: true, message: "请输入客户名称", trigger: "blur" },
        ],
        cCusCode: [
          { required: true, message: "请输入客户编号", trigger: "blur" },
        ],
        cProvince: [
          { required: true, message: "请选择省/直辖市", trigger: "change" },
        ],
        cCity: [{ required: true, message: "请选择市", trigger: "change" }],
        SmsCode: [
          { required: true, message: "请输入静态验证码", trigger: "blur" },
        ],
      },
      btnLoading: false,
      title: "",
      ProvinceList: [],
      CityList: [],
      CountyList: [],
    };
  },
  methods: {
    // 公司选择
    CompanySelect(row) {
      console.log(row);
    },
    handleAvatarSuccess(res, file) {},
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      if (!isJPG) {
        return this.$message.error("请选择JPG/PNG格式图片");
      }
      var _this = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        // 图片base64化
        var newUrl = this.result; //图片路径
        _this.$set(_this.entity, "CustomerSign", newUrl);
      };
    },
    init() {
      this.drawer = true;
      let numArr = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      let str = "";
      for (let i = 0; i < 6; i++) {
        str += numArr[parseInt(Math.random() * 10)];
      }
      this.entity = { IscheckPhone: false, ISsignCode: true, SmsCode: str };
      // this.entity.SmsCode = str;
      // this.$set(this.entity, "ISsignCode", true);
      // this.$set(this.entity, "SmsCode", str);
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    openForm(Id, CompanyId) {
      this.init();
      if (CompanyId) {
        this.entity.CompanyId = CompanyId;
      }
      this.title = "新增客户";
      if (Id) {
        this.title = "编辑客户";
        this.getTheData(Id);
      }
      this.getProvinceList();
    },
    handleClose(done) {
      done();
    },
    getTheData(id) {
      this.$http.post("/BO/CustomerInfo/GetTheData", { id }).then((res) => {
        if (res.Success) {
          this.entity = res.Data;
          if (this.entity.cProvince) this.getCityList(this.entity.cProvince);
          if (this.entity.cCity) this.getCountyList(this.entity.cCity);
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    changeProvince(e) {
      this.$set(this.entity, "cCity", "");
      this.$set(this.entity, "cCounty", "");
      this.getCityList(e);
    },
    changeCity(e) {
      this.$set(this.entity, "cCounty", "");
      this.getCountyList(e);
    },
    // 省列表
    getProvinceList() {
      this.$http
        .post("/Base_BasicData/Base_District/GetDataListByPId?pId=0", {})
        .then((resJson) => {
          this.ProvinceList = resJson.Data;
        });
    },
    // 市列表
    getCityList(id) {
      this.$http
        .post("/Base_BasicData/Base_District/GetDataListByPId?pId=" + id, {})
        .then((resJson) => {
          this.CityList = resJson.Data;
        });
    },
    // 区列表
    getCountyList(id) {
      this.$http
        .post("/Base_BasicData/Base_District/GetDataListByPId?pId=" + id, {})
        .then((resJson) => {
          this.CountyList = resJson.Data;
        });
    },
    handleSubmit() {
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return;
        }
        this.btnLoading = true;
        this.$http
          .post("/BO/CustomerInfo/SaveData", this.entity)
          .then((res) => {
            this.btnLoading = false;
            if (res.Success) {
              this.$message.success("操作成功");
              this.parentObj.getDataList();
              this.drawer = false;
            } else {
              this.$message.error(res.Msg);
            }
          });
      });
    },
  },
};
</script>

<style>
.drawerClass {
  padding: 10px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: #fff;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 200px;
  line-height: 200px;
  text-align: center;
}
.avatar {
  width: 200px;
  height: 200px;
  display: block;
}
</style>
